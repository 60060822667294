<template>
  <div :class="(playing) ? 'bg-red-700' : 'bg-blue-600 hover:bg-blue-500'" class="relative border-white border-solid border-2 h-16  text-white rounded shadow m-2 " @click="play()">
    <div class="bg-white w-0 h-1 absolute top-0 inset-0 w-64 transition-all duration-150" :style="'width:'+progress+'%'"></div>
    <div class="h-full flex pl-4 pr-4 items-center">
      <div>{{name}}</div>
    </div>
    <div class="absolute right-0.5 bottom-0.5 text-sm bg-yellow-400 text-black w-16 rounded-2xl pl-2 pr-2" v-if="nieuw">
      NIEUW
    </div>
  </div>
</template>

<script>

export default {
  name: 'Soundbutton',
  props: {
    name: String,
    file: String,
    nieuw: Boolean
  },
  data(){
    return {
      progress:0,
      audio: null,
      playing: false
    }
  },
  mounted() {
    this.audio = new Audio('./'+this.file);
    this.audio.addEventListener("timeupdate",()=>{
      this.progress=Math.round(this.audio.currentTime/this.audio.duration*100);
    });
    this.audio.addEventListener("play",()=>{
      this.playing=true;
    });
    this.audio.addEventListener("ended",()=>{
      this.playing=false;
    });
  },
  methods:{
    play(){
      this.audio.currentTime=0;
      this.audio.play();
      window.gtag('event', this.name, {
        'event_category': 'sample_play',
        'event_label': this.name
        });
    },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
