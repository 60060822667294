<template>
  <div id="app">
    <img alt="Vue logo" class="h-48 m-auto mb-4" src="./assets/icon.webp">
    <Install></Install>
    <Board class="m-auto max-w-screen-md" :buttons="buttons">

    </Board>
    <div class="text-white text-3xl text-center"><a href="https://maxvdbosch.nl">♥</a></div>
  </div>
</template>

<script>
import Board from './components/Board.vue'

export default {
  name: 'App',
  components: {
    Board
  },
  data(){
    return {
      buttons: require('./samples.json')
    }
  },
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
