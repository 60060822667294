<template>
  <div class="flex flex-wrap justify-center">
    <Soundbutton :file="button.file" :name="button.name" :nieuw="button.new" v-for="button in buttons" :key="button.name"></Soundbutton>
  </div>
</template>

<script>
import Soundbutton from "@/components/Soundbutton";

export default {
  name: 'Board',
  props: {
    buttons: Array
  },
  components: {
    Soundbutton
  },
  data(){
    return {

    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
